.navbar {
  background-color: #333;
  position: sticky;
  top: 0;
  overflow: hidden;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar ul li {
  list-style-type: none;
}

.navbar ul li a {
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.navbar ul li a:hover {
  background-color: #ddd;
  color: black;
}

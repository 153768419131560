.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #fb9c61;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.project-title {
  font-size: 3em; /* Make the title larger */
  text-align: left;
  color: white
}

.project-readme img {
  width: 150%; /* Make images smaller. Adjust this as necessary */
  max-width: 350px;
  height: auto;
}

.project-readme h1, 
.project-readme h2, 
.project-readme h3, 
.project-readme h4, 
.project-readme h5, 
.project-readme h6 {
  font-size: 1em; /* Make headings smaller. Adjust this as necessary */
}


body {
  background-color: #333; /* Add a nice color background. Change this color to what you prefer */
}

.title {
  text-align: center;
  margin: 2em 0;
  color: white
}

.project {
  width: 80%; /* Make the text under each README heading less wide */
  margin: 0;
  padding-left: 20px;
  text-align: left;
  color: white
}

.project-title {
  font-size: 2em; 
  cursor: pointer;
}

.project-readme img {
  width: 100%;
  max-width: 300px;
  height: auto;
}

.repo-link {
  color: #0366d6; /* Change the link color to what you prefer */
  text-decoration: none;
}

.repo-link:hover {
  text-decoration: underline;
}

/* Keep your existing styles for .about-heading and .about-paragraph */

.about-heading {
  margin: 0;
  padding: 20px; /* Add padding to all sides */
  text-align: left;
  color: white;
  font-size: 1.5rem; /* Adjust font size for smaller screens */
}

.about-paragraph {
  margin: 0;
  padding: 20px; /* Add padding to all sides */
  text-align: left;
  color: white;
  line-height: 1.6; /* Adjust line height for better readability */
  font-size: 1rem; /* Adjust font size for smaller screens */
}

/* New styles for social links and icons */

.social-links {
  display: flex;
  margin-top: 20px;
}

.social-icon {
  width: 30px;
  height: 30px;
  padding-left: 20px;
  margin-right: 10px;
  transition: transform 0.2s ease-in-out;
}

.social-icon:hover {
  transform: scale(1.2);
}



